<template>
  <div>
    <el-table v-loading="loading" :data="tableData" border max-height="705" style="width: 100%">
      <el-table-column label="#" type="index"/>
      <el-table-column label="身份" prop="type" sortable/>
      <el-table-column label="令牌" prop="value"/>
      <!-- 操作栏 -->
      <el-table-column label="操作" width="130px">
        <template #default="scope">
          <el-button icon="Edit" type="primary" @click="handleEdit(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import request from "@/utils/request";
import loading from "@element-plus/icons-vue/dist/es/loading.mjs";
const { ElMessage } = require("element-plus");

export default {
  computed: {
    loading() {
      return loading;
    },
  },
  data() {
    return {
      showpassword: true,
      judgeAddOrEdit: true,
      loading: true,
      editJudge: true,
      disabled: false,
      judge: false,
      dialogVisible: false,
      search: "",
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {
        type: "",
        value: "",
        id: "",
      },
    };
  },
  created() {
    this.load();
    this.loading = true;
    setTimeout(() => {
      // 设置延迟执行
      this.loading = false;
    }, 1000);
  },
  methods: {
    // 加载数据
    async load() {
      request
          .get("/tokens/writer", {
            params: {
              pageNum: this.currentPage,
              pageSize: this.pageSize,
              search: this.search,
            },
          })
          .then((res) => {
            console.log(res);
            this.tableData = res.data.records;
            this.total = res.data.total;
            this.loading = false;
          });
    },
    // 处理编辑操作
    handleEdit(row) {
      // 生拷贝 row 数据到 form 对象
      this.form = { ...row };
      // 判断操作类型
      this.judge = true;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.judgeAddOrEdit = true;
        this.editDisplay = { display: "block" };
        this.disabled = true;
      });
      // 发起修改请求
      request.put("/tokens/update", this.form).then((res) => {
        console.log(res);
        if (res.code === "0") {
          ElMessage({
            message: "修改成功",
            type: "success",
          });
          this.search = "";
          this.load();
          this.dialogVisible = false;
        } else {
          ElMessage({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>
